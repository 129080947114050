<template>
	<div class="about_us">
		<div class="head">
            <img src="../assets/img/icons/circle-two2.svg" alt="" class="circle">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v">
			<img src="../assets/img/icons/rect-layer2.svg" alt="" class="rect">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v2">
        </div>

		<div class="about">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<h2 class="title">{{about.title}}</h2>
							<div class="desc" v-html="about.desc"></div>
							<div class="vimis">
								<img src="../assets/img/icons/petik-atas.svg" class="petik_atas" alt="">
								<img src="../assets/img/icons/petik-bawah.svg" class="petik_bawah" alt="">
								<h2>Visi</h2>
								<p v-html="about.vision"></p>
							</div>
							<div class="vimis">
								<img src="../assets/img/icons/petik-atas.svg" class="petik_atas" alt="">
								<img src="../assets/img/icons/petik-bawah.svg" class="petik_bawah" alt="">
								<h2>Misi</h2>
								<div v-html="about.mission"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'About',
    components: {
        Footer,
    },
	setup(){
		const about = ref([])

		onMounted(() => {
			axios.get('getAbout')
			.then((result) => {
				about.value = result.data[0]
				console.log('About : ', result.data)
			}).catch((err) => {
				console.log(err.response)
			})
		})

		return{
			about,
		}
	}
}
</script>

<style lang="scss" scoped>
.about_us{
	.head{
        background: $blue;
		position: relative;
		height: 300px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 400px;
			height: auto;
			z-index: 2;
			opacity: 0.06;
		}
		.dot_v{
			position: absolute;
			top: 20px;
			left: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
			opacity: 0.06;
		}
		.dot_v2{
			position: absolute;
			bottom: 20px;
			right: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
			opacity: 0.06;
		}
		.rect{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 420px;
			height: auto;
			z-index: 1;
			opacity: 0.06;
		}
    }

	.about{
		margin: -300px 0 0;
		.box{
			background: white;
			padding: 40px 60px;
			margin: 100px 0 40px;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
			position: relative;
			z-index: 4;
			.title{
				text-align: center;
				text-transform: uppercase;
				color: $blue;
				font-weight: 700;
				margin: 0 0 20px 0;
			}
			.desc{
				text-align: center;
				color: $darkGrey;
				font-family: "Poppins", sans-serif;
				text-align: justify;
			}
			.vimis{
				position: relative;
				border: 1px solid $grey;
				padding: 40px;
				margin: 50px 0;
				h1, h2, h3, h4, h5{
					color: $blue;
					font-weight: 700;
					text-transform: uppercase;
				}
				p{
					color: $darkGrey;
					text-align: justify;
				}
				ol{
					counter-reset: my-awesome-counter;
					list-style: none;
					li{
						font-size: 12pt;
						margin: 0 0 4px 20px;
						text-align: justify;
						counter-increment: my-awesome-counter;
						position: relative;
						&::before{
							content: counter(my-awesome-counter) ".";
							color: $blue;
							// border: 1px solid $blue;
							font-size: 14pt;
							font-weight: bold;
							position: absolute;
							--size: 34px;
							left: calc(-1 * var(--size) - 10px);
							line-height: var(--size);
							width: var(--size);
							height: var(--size);
							top: -4px;
							text-align: center;
						}
					}
				}
				.petik_atas{
					position: absolute;
					top: -10px;
					left: -10px;
					width: 50px;
					height: auto;
				}
				.petik_bawah{
					position: absolute;
					bottom: -10px;
					right: -10px;
					width: 50px;
					height: auto;
				}
			}
		}
	}
	@media (max-width: 767.98px){
		.about{
			.box{
				padding: 40px 20px;
				.vimis{
					padding: 20px;
					.petik_atas{
						width: 30px;
						height: auto;
					}
					.petik_bawah{
						width: 30px;
						height: auto;
					}
				}
			}
		}
	}
}
</style>
